import React from 'react'
import { Seo } from '../../../components'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'
import { TermsAndConditionsMobile } from '../../../sections'
import { FullScreen } from '../../../components/fullScreen'
import { colors } from '../../../utils/const'

const DarkPrivacyPolicy = () => {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <FullScreen theme={colors.darkTheme}>
            <Seo
                title={t('terms_and_conditions_seo_title')}
                description={t('terms_and_conditions_seo_description')}
            />
            <TermsAndConditionsMobile
                locale={intl.locale}
            ></TermsAndConditionsMobile>
        </FullScreen>
    )
}

export default injectIntl(DarkPrivacyPolicy)
